var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manager-container" }, [
    _c(
      "div",
      { staticClass: "query-item button-bar" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "danger", round: "" },
            on: {
              click: function ($event) {
                return _vm.handleClearCacheClick()
              },
            },
          },
          [_vm._v(" Clear MPA Product Cache ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-block" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.mpaFuelTypeList,
              "cell-style": _vm.CELL_STYLE,
              "header-cell-style": _vm.HEADER_CELL_STYLE,
              height: "100%",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "Description", "min-width": "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.fuelTypeDescription ?? "-") +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Code" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " + _vm._s(scope.row.fuelTypeCode ?? "-") + " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Bunker Type", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(" " + _vm._s(scope.row.bunkerType ?? "-") + " "),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Oil Type", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.oilType,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "oilType", $$v)
                            },
                            expression: "scope.row.oilType",
                          },
                        },
                        _vm._l(_vm.OIL_TYPE_OPTIONS, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Fuel Grade Type", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.fuelGradeType,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "fuelGradeType", $$v)
                            },
                            expression: "scope.row.fuelGradeType",
                          },
                        },
                        _vm._l(_vm.FUEL_GRADE_TYPE_OPTIONS, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Sulfur Start >", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.sulfurStart,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "sulfurStart", $$v)
                          },
                          expression: "scope.row.sulfurStart",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Sulfur End <=", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.sulfurEnd,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "sulfurEnd", $$v)
                          },
                          expression: "scope.row.sulfurEnd",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Viscosity Start >=", width: "125" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.viscosityStart,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "viscosityStart", $$v)
                          },
                          expression: "scope.row.viscosityStart",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Viscosity End <=", width: "125" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.viscosityEnd,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "viscosityEnd", $$v)
                          },
                          expression: "scope.row.viscosityEnd",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Water Content End <=", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { disabled: "", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.waterMax,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "waterMax", $$v)
                          },
                          expression: "scope.row.waterMax",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Flash Point Start >=", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { disabled: "", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.flashPointMin,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "flashPointMin", $$v)
                          },
                          expression: "scope.row.flashPointMin",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Density End <=", width: "125" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { disabled: "", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.densityMax,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "densityMax", $$v)
                          },
                          expression: "scope.row.densityMax",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Fuel Grade Subtype", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { disabled: "", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.fuelGradeSubtype,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "fuelGradeSubtype", $$v)
                          },
                          expression: "scope.row.fuelGradeSubtype",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Value Index", width: "125" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-input", {
                        attrs: { disabled: "", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.valueIndex,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "valueIndex", $$v)
                          },
                          expression: "scope.row.valueIndex",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Updated At", width: "200px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            scope.row?.updatedAt
                              ? _vm
                                  .$moment(scope.row.updatedAt)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : "-"
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }